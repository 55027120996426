"use client";

export const OfflineNotification = () => {
  return (
    <div
      className={
        "bg-red-R600 text-neutral-N0 fixed inset-x-0 top-2.5 z-20 mx-auto inline-block w-fit max-w-[18rem] rounded-xl p-2 md:top-4 md:max-w-full"
      }
      role={"alert"}
    >
      <p
        className={"body-b3 text-center"}
        data-testid={"order-cashier__text__offline-information"}
      >
        Transaksi tidak dapat dilakukan karena tidak ada koneksi internet. Cek
        kembali koneksi internet anda
      </p>
    </div>
  );
};

export default OfflineNotification;
